@import '../Shared/Shared.scss';

.no-match {
	@include flex-center-center;
	height: 80%;
	.no-match-title {
		margin: 10rem 0;
		@include red-font-bold;
		font-size: 4.3rem;
	}
}
