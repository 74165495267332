@import '../Shared/Shared.scss';

.clients-container {
	width: 100%;
	@include flex-center-center;

	.clients {
		@include flex-center-center;
		flex-wrap: wrap;
		flex-direction: row;
		width: 100%;
		height: auto;
		max-width: 124.6rem;

		.client-item {
			margin: 2rem 0;
			width: 30%;

			img {
				height: 80%;
			}
		}
	}

	@media (max-width: 1024px) {
	.clients {
		.client-item {
			img {
				height: 70%;
			}
		}
	}
}

@media (max-width: 850px) {
	.clients {
		.client-item {
			width: 32%;

			img {
				height: 60%;
			}
		}
	}
}

@media (max-width: 800px) {
	.clients {
		.client-item {
			img {
				width: 80%;
			}
		}
	}
}

@media (max-width: 750px) {
	.clients {
		.client-item {
			width: 30%;
		}
	}
}
}
