@import '../Shared/Shared.scss';

.landing {
	margin-top: 10rem;

	.studies-title-container {
		width: 100%;
		@include flex-center-center;
		flex-flow: column;
		.studies-title {
			@include red-font-bold;
			font-size: 4.3rem;
		}

		.studies-bar {
			margin-bottom: 3rem;
			background-color: $red;
			height: 0.6rem;
			width: 10rem;
		}
	}
}
