@import './Shared.scss';

.contact-section {
	height: 36rem;
	background-color: $blue;
	color: $white;
	@include flex-center-center;
	flex-flow: column;

	.contact-container {
		width: 90%;
		@include flex-center-center;
		flex-flow: column;
		h2 {
			font-size: 4.3rem;
			margin: 0;
			font-family: DINNextLTPro-bold;
		}

		h3 {
			font-family: DINNextLTPro-light;
			font-size: 3.4rem;
		}

		a {
			text-decoration: none;
			@include flex-center-center;
			@include blue-font-medium;
			border: none;
			background-color: $white;
			height: 4rem;
			width: 23rem;
			font-size: 1.8rem;
		}

		a:hover {
			background-color: $red;
			color: $white;
			font-size: 1.8rem;
			cursor: pointer;
		}
	}
}

@media (max-width: 710px) {
	.contact-section {
		.contact-container {
			width: 90%;
			h2 {
				font-size: 3rem;
			}

			h3 {
				font-size: 2rem;
			}

			a {
				height: 4rem;
				width: 23rem;
				font-size: 1.8rem;
			}

			a:hover {
				font-size: 1.8rem;
			}
		}
	}
}

@media (max-width: 550px) {
	.contact-section {
		.contact-container {
			width: 90%;
			h2 {
				font-size: 2.5rem;
			}

			h3 {
				font-size: 1.5rem;
			}

			a {
				height: 4rem;
				width: 23rem;
				font-size: 1.8rem;
			}

			a:hover {
				font-size: 1.8rem;
			}
		}
	}
}

@media (max-width: 400px) {
	.contact-section {
		.contact-container {
			width: 90%;
			h2 {
				font-size: 2.5rem;
			}

			h3 {
				font-size: 1.4rem;
			}

			a {
				height: 4rem;
				width: 23rem;
				font-size: 1.8rem;
			}

			a:hover {
				font-size: 1.8rem;
			}
		}
	}
}

@media (max-width: 375px) {
	.contact-section {
		.contact-container {
			width: 90%;
			h2 {
				font-size: 2.2rem;
			}

			h3 {
				font-size: 1.1rem;
			}

			a {
				height: 4rem;
				width: 23rem;
				font-size: 1.8rem;
			}

			a:hover {
				font-size: 1.8rem;
			}
		}
	}
}
