@import '../Shared/Shared.scss';

.project {
	.other-projects-container {
		width: 100%;
		@include flex-center-center;
		flex-flow: column;
		.other-projects-title {
			@include red-font-bold;
			font-size: 3.2rem;
		}

		.info-bar {
			margin-bottom: 3rem;
			background-color: $red;
			height: 0.6rem;
			width: 10rem;
		}
	}

	.projects-back-container {
		width: 100%;
		@include flex-center-center;

		.projects-back {
			width: 80%;
			display: flex;
			justify-content: flex-end;
			.projects-back-link {
				text-decoration: none;
				@include dark-grey-font-heavy;
				font-size: 2rem;
			}
		}
	}
}

@media (max-width: 550px) {
	.project {
		.projects-back-container {
			.projects-back {
				.projects-back-link {
					font-size: 1.5rem;
				}
			}
		}
	}
}
