@import './Shared.scss';

.footer {
	height: 18.7rem;
	width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;

	.footer-top {
		flex: 1;
	}

	.footer-center {
		display: flex;
		height: 100%;

		.footer-piece {
			flex: 1;
			height: 100%;
			@include flex-center-center;
		}

		#footer-logo {
			a {
				height: 100%;
				width: 100%;
				@include flex-center-center;
			}

			img {
				height: 30%;
			}
		}

		#footer-contact {
			flex-flow: column;
			font-size: 2rem;

			h3 {
				font-family: DINNextLTPro-bold;
				margin: 0;
			}

			p {
				font-family: DINNextLTPro-normal;
				margin: 0;
			}
			span {
				display: list-item;
				list-style: none;
			}
		}

		#footer-social {
			flex-flow: column;
			.social-filler {
				flex: 1;
			}

			.social-container {
				@include flex-center-center;
				a {
					margin: 0 0.5rem;
					min-width: 8rem;
					@include flex-center-center;

					.social-icon {
						width: 100%;
						@include flex-center-center;
						&:hover {
							.bg-circle-blue {
								color: $red;
							}						
						}
					}

					.bg-circle-blue {
						color: $blue;
						font-size: 3rem;
						&:hover {
							color: $red;
						}
					}
					.bg-circle-red {
						color: $red;
						font-size: 3rem;
					}

					.facebook {
						font-size: 3rem;
						color: $white;
					}
					.linkedin {
						font-size: 3rem;
						color: $white;
					}
				}
			}

			#footer-copyright {
				@include flex-center-center;
				font-size: 2rem;
				font-family: DINNextLTPro-light;

				h4 {
					margin: 0;
				}
			}
		}
	}

	.footer-bottom {
		flex: 1;
	}
}

@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		.footer {
			.footer-center {
				@include flex-center-center;
			}
		}
	}
}

@media (max-width: 1000px) {
	.footer {
		.footer-center {
			#footer-fill {
				display: none;
			}
		}

		.footer-bottom {
			#footer-copyright {
				padding-right: 10%;
			}
		}
	}
}

@media (max-width: 760px) {
	.footer {
		height: 50rem;

		.footer-center {
			flex: 9;
			flex-flow: column;

			#footer-fill {
				display: none;
			}
		}

		.footer-bottom {
			#footer-copyright {
				justify-content: center;
				padding-right: 0;
			}
		}
	}
}
