@import '../Shared/Shared.scss';

.employees {
	@include flex-center-center;
	margin: 7rem 0;

	.employees-grid {
		display: grid;
		grid-template-columns: 20% 20% 20% 20% 20%;
		grid-auto-rows: 1fr;
		width: 90%;
		max-width: 144.7rem;

		.employees-item {
			display: grid;
			grid-template-columns: 100%;
			grid-template-rows: 70% 30%;
			.employees-name-container {
				@include flex-center-center;
				.employees-name {
					font-family: DINNextLTPro-bold;
					font-size: 2.8rem;

					span {
						display: block;
					}
				}
			}

			.employees-title-container {
				@include flex-center-center;
				flex-flow: column;
				justify-content: flex-start;
				.employees-title {
					margin: 0;
					font-family: DINNextLTPro-normal;
					font-size: 2rem;
					font-weight: 400;
				}
			}
		}
	}
}

@media (max-width: 1000px) {
	.employees {
		.employees-grid {
			display: grid;
			grid-template-columns: 50% 50%;
			grid-auto-rows: 1fr;
			width: 80%;
		}
	}
}

@media (max-width: 500px) {
	.employees {
		.employees-grid {
			.employees-item {
				.employees-name-container {
					.employees-name {
						font-size: 2.5rem;
					}
				}
				.employees-title-container {
					.employees-title {
						font-size: 1.7rem;
					}
				}
			}
		}
	}
}
