@import '../Shared/Shared.scss';

.services-container {
	display: flex;
	justify-content: center;
	.services-grid {
		display: grid;
		grid-template-columns: 50% 50%;
		grid-auto-rows: 1fr;
		width: 80%;
		max-width: 124.6rem;

		.photo {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		#photo-quantitative {
			background-image: url('../../images/home/Quantitative.png');
		}

		#photo-qualitative {
			background-image: url('../../images/home/Qualitative.png');
		}

		#photo-online {
			background-image: url('../../images/home/Online.png');
		}

		#photo-logistique {
			background-image: url('../../images/home/Logistique.png');
		}

		.info-container {
			flex: 1;
			background-color: $light-grey;
			@include flex-center-center;

			.service-info {
				width: 80%;
				display: flex;
				padding: 3rem 0;

				.info-bar-container {
					flex: 1;

					.info-bar {
						height: 0.5rem;
						width: 80%;
						background-color: $red;
						margin: 2rem 0;
						max-width: 4rem;
					}
				}

				.info-data-container {
					flex: 10;

					.info-title {
						text-align: left;
						@include red-font-bold;
						font-size: 3.4rem;
						margin: 0 0 1rem;

						span {
							display: list-item;
							list-style: none;
						}
					}

					p {
						font-size: 3rem;
						text-align: left;
						margin: 1rem 0;
						@include blue-font-normal;
					}

					.info-btn {
						@include flex-center-center;
						text-decoration: none;
						float: left;
						min-height: 4rem;
						height: 4rem;
						width: 23rem;
						background-color: $dark-grey;
						border: none;
						@include white-font-medium;
						cursor: pointer;
						font-size: 1.8rem;
						margin-top: 2rem;
					}

					.info-btn:hover {
						background-color: $red;
					}
				}
			}
		}
	}
}

@media (max-width: 1950px) {
	.services-container {
		.services-grid {
			.info-container {
				.service-info {
					.info-data-container {
						p {
							font-size: 2.6rem;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1250px) {
	.services-container {
		.services-grid {
			.info-container {
				.service-info {
					.info-data-container {
						.info-title {
							font-size: 3.2rem;
						}
						p {
							font-size: 2.4rem;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1000px) {
	.services-container {
		.services-grid {
			.info-container {
				.service-info {
					.info-data-container {
						.info-title {
							font-size: 3.2rem;
						}
						p {
							margin: 0;
							font-size: 2rem;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.services-container {
		.services-grid {
			.info-container {
				.service-info {
					.info-data-container {
						.info-title {
							font-size: 3.2rem;
						}
						p {
							margin: 0;
							font-size: 2rem;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 760px) {
	.services-container {
		.services-grid {
			grid-template-columns: 100%;

			.service-item:nth-of-type(1) {
				order: 1;
			}
			.service-item:nth-of-type(2) {
				order: 2;
			}
			.service-item:nth-of-type(3) {
				order: 4;
			}
			.service-item:nth-of-type(4) {
				order: 3;
			}
			.service-item:nth-of-type(5) {
				order: 5;
			}
			.service-item:nth-of-type(6) {
				order: 6;
			}
			.service-item:nth-of-type(7) {
				order: 8;
			}
			.service-item:nth-of-type(8) {
				order: 7;
			}

			.info-container {
				flex: 1;
				background-color: $light-grey;

				.service-info {
					.info-bar-container {
						display: none;
					}
					.info-data-container {
						display: flex;
						flex-flow: column;
						justify-content: center;
						align-items: center;
						.info-title {
							text-align: center;
							span {
								display: inline;
								list-style: none;
							}
						}
						p {
							text-align: center;
						}

						.info-btn {
							float: none;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 570px) {
	.services-container {
		.services-grid {
			.info-container {
				flex: 1;
				background-color: $light-grey;

				.service-info {
					.info-bar-container {
						display: none;
					}
					.info-data-container {
						.info-title {
							text-align: center;
							font-size: 2rem;
							span {
								display: inline;
								list-style: none;
							}
						}
						p {
							text-align: center;
							font-size: 1.8rem;
						}

						.info-btn {
							float: none;
						}
					}
				}
			}

			.change {
				flex-direction: column-reverse;
			}
		}
	}
}

@media (max-width: 400px) {
	.services-container {
		.services-grid {
			.info-container {
				flex: 1;
				background-color: $light-grey;

				.service-info {
					.info-bar-container {
						display: none;
					}
					.info-data-container {
						.info-btn {
							float: none;
							width: 18rem;
						}
					}
				}
			}

			.change {
				flex-direction: column-reverse;
			}
		}
	}
}

@media (max-width: 350px) {
	.services-container {
		.services-grid {
			.info-container {
				flex: 1;
				background-color: $light-grey;

				.service-info {
					.info-bar-container {
						display: none;
					}
					.info-data-container {
						.info-title {
							text-align: center;
							font-size: 1.8rem;
							span {
								display: inline;
								list-style: none;
							}
						}
						p {
							text-align: center;
							font-size: 1.5rem;
						}

						.info-btn {
							float: none;
						}
					}
				}
			}

			.change {
				flex-direction: column-reverse;
			}
		}
	}
}
