@import '../Shared/Shared.scss';

.filters {
	margin-top: 5rem;
	width: 100%;
	@include flex-center-center;

	.filters-container {
		@include flex-between-center;
		margin: 0 2rem;

		.filter-item {
			background-color: $light-grey;
			@include flex-between-center;
			text-decoration: none;
			@include blue-font-normal;
			font-size: 1.8rem;
			margin: 0 1rem;

			.filter-icon {
				min-height: 2.8rem;
				min-width: 2.8rem;
				margin-left: 2rem;
			}

			.quantitative {
				@include icon('../../images/shared/icons/Quantitative-blue.png');
			}
			.qualitative {
				@include icon('../../images/shared/icons/Qualitative-blue.png');
			}
			.online {
				@include icon('../../images/shared/icons/Online-blue.png');
			}
			.logistics {
				@include icon('../../images/shared/icons/Logistics-blue.png');
			}

			h3 {
				margin: 1rem 2rem 1rem 1rem;
			}
		}

		.filter-item:hover {
			background-color: $blue;
			@include white-font-normal;
			.quantitative {
				@include icon('../../images/shared/icons/Quantitative-white.png');
			}
			.qualitative {
				@include icon('../../images/shared/icons/Qualitative-white.png');
			}
			.online {
				@include icon('../../images/shared/icons/Online-white.png');
			}
			.logistics {
				@include icon('../../images/shared/icons/Logistics-white.png');
			}
		}

		.filter-item-selected {
			background-color: $blue;
			@include white-font-normal;
			.quantitative {
				@include icon('../../images/shared/icons/Quantitative-white.png');
			}
			.qualitative {
				@include icon('../../images/shared/icons/Qualitative-white.png');
			}
			.online {
				@include icon('../../images/shared/icons/Online-white.png');
			}
			.logistics {
				@include icon('../../images/shared/icons/Logistics-white.png');
			}
		}
	}
}

@media (max-width: 1350px) {
	.filters {
		.filters-container {
			.filter-item {
				font-size: 1.5rem;
			}
		}
	}
}

@media (max-width: 1080px) {
	.filters {
		.filters-container {
			.filter-item {
				font-size: 1.3rem;
			}
		}
	}
}

@media (max-width: 991px) {
	.filters {
		.filters-container {
			flex-wrap: wrap;
			// max-width: 67rem;
			@include flex-center-center;
			.filter-item {
				margin-top: 1rem;
				// max-width: 31.4rem;
				flex: 1 1 47%;
				font-size: 1.8rem;
				@include flex-center-center;
			}
		}
	}
}

@media (max-width: 350px) {
	.filters {
		.filters-container {
			.filter-item {
				font-size: 1.4rem;
			}
		}
	}
}
