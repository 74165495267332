@import '../Shared/Shared.scss';

.form {
	background-color: $blue;
	@include flex-center-center;
	flex-flow: column;

	.form-title-container {
		margin-top: 6rem;
		color: $white;
		margin-bottom: 3rem;
		width: 90%;

		h2 {
			margin-top: 0;
			font-size: 4.3rem;
			margin-bottom: 2rem;
		}

		h3 {
			font-size: 2rem;
		}
	}

	.form-container-grid {
		display: grid;
		grid-template-columns: 48% 48%;
		grid-column-gap: 2rem;
		grid-row-gap: 2rem;
		grid-auto-rows: auto;
		width: 60%;
		height: 80%;
		max-width: 86.1rem;

		input {
			font-size: 2rem;
			padding: 0;
			border: 0.1rem solid black;
			padding-left: 1rem;
			height: 5rem;
		}

		input::placeholder {
			color: $dark-grey;
			font-size: 2rem;
		}

		.input-missing {
			padding: 0;
			padding-left: 1rem;
			border: 0.1rem solid black;
			box-shadow: inset 0 0 0 0.3rem red;
		}

		textarea {
			padding: 1rem;
			font-size: 2rem;
			grid-column-start: 1;
			grid-column-end: 3;
		}

		textarea::placeholder {
			color: $dark-grey;
			font-size: 2rem;
		}
	}

	.form-button-response-container {
		margin-bottom: 6rem;

		h4 {
			color: $red;
			height: 2rem;
			font-size: 2rem;
		}

		.form-button {
			background-color: $white;
			color: $dark-grey;
		}

		.form-button:hover {
			background-color: $red;
			color: $white;
		}
	}
}

@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		.form {
			.form-container-grid {
				display: flex;
				height: auto;
				flex-wrap: wrap;

				.form-item {
					flex: 1 0 40%;
					margin: 0.5rem;
				}
			}
		}
	}
}

@supports not (display: grid) {
}

@media (max-width: 1600px) {
	.form {
		.form-container-grid {
			width: 80%;
		}
	}
}

@media (max-width: 1000px) {
	.form {
		.form-title-container {
			h2 {
				font-size: 3.8rem;
			}
		}

		.form-container-grid {
			width: 90%;
		}
	}
}

@media (max-width: 750px) {
	.form {
		.form-container-grid {
			width: 90%;
		}
	}
}

@media (max-width: 750px) {
	.form {
		.form-container-grid {
			width: 90%;
			grid-template-columns: 100%;
			grid-auto-rows: auto;

			textarea {
				grid-column-start: 1;
				grid-column-end: 2;
			}
		}
	}
	@supports (-webkit-appearance: none) {
		.form {
			.form-container-grid {
				display: flex;
				height: auto;
				flex-flow: column;

				.form-item {
					flex: 1;
					margin: 0;
				}

				textarea {
					flex: 3 !important;
				}
			}
		}
	}
}
