@import './Shared.scss';

@mixin active-nav-item {
	.selection-bar {
		background-color: $red;
	}

	.main-li-a {
		color: $red;

		h2 {
			color: $red;
		}
	}
}

@mixin active-sub-nav-item {
	.sub-selection-bar {
		background-color: $red;
	}

	h2 {
		color: $red;
	}
}

header {
	height: $nav-height;
	width: 100%;
	@include flex-between-center;
	position: fixed;
	top: 0;
	background-color: $white;
	z-index: 2;
	border-bottom: 0.1rem solid $dark-grey;
	-webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.75);
	max-width: $max-width;
	.logo {
		padding-left: 3rem;
		@include flex-start-center;
		flex: 1;

		a {
			@include flex-start-center;
			img {
				max-width: 13.4rem;
			}
		}
	}

	nav {
		width: 70%;
		height: 100%;
		flex: 3;
		@include flex-evenly-center;

		.link-list {
			width: 100%;
			height: 100%;
			@include flex-evenly-center;
			@include zero-margin-padding;

			.main-li {
				@include column-between-center;
				align-items: unset;
				height: 100%;
				list-style: none;
				cursor: pointer;

				.main-li-a {
					@include column-between-center;
					height: 100%;
					text-decoration: none;

					h2 {
						font-weight: 400;
						margin: 0;
						font-size: 2rem;
						@include blue-font-normal;
					}

					.selection-bar {
						height: 0.8rem;
						width: 100%;
					}

					.filler-bar {
						height: 0.8rem;
						width: 100%;
					}
				}

				.main-li-a:hover {
					color: $red;
					h2 {
						@include red-font-normal;
					}
				}

				.desktop-sub-menu {
					top: $nav-height;
					position: absolute;
					background-color: $light-grey;
					height: auto;
					display: none;
					padding: 0;
					flex-flow: column;
					width: 40rem;

					.sub-li {
						@include column-between-center;
						align-items: unset;
						height: 5rem;
						background-color: $grey;
						width: 100%;
						border-bottom: 1px solid $blue;

						.sub-link {
							height: 100%;
							text-decoration: none;
							@include column-between-start;
							h2 {
								padding-left: 2rem;
								margin: 0;
								font-weight: 500;
								font-size: 2.2rem;
								text-decoration: none;
								@include blue-font-light;
							}
							.sub-selection-bar {
								height: 0.8rem;
								width: 100%;
							}
						}
					}
				}
			}

			.main-li.active {
				@include active-nav-item;
			}

			.main-li:hover {
				@include active-nav-item;
			}

			.desktop-sub-menu.show {
				display: flex;
				.sub-li:hover {
					@include active-sub-nav-item;
				}

				.sub-li.active {
					@include active-sub-nav-item;
				}
			}

			.li-languages {
				@include flex-center-center;
				cursor: default;
				font-weight: 400;
				margin: 0;
				font-size: 2.2rem;
				flex-flow: row;
				@include dark-grey-font-normal;

				.li-lang {
					font-weight: 400;
					margin: 0;
					font-size: 2rem;
					background-color: $white;
					border: none;
					width: 3rem;
					padding: 0;
					cursor: pointer;
					@include dark-grey-font-normal;
				}

				.active-lang {
					@include red-font-bold;
					text-decoration: underline;
				}
				.li-lang:hover {
					@include red-font-bold;
					text-decoration: underline;
				}
			}
		}
	}

	.menu-toggle {
		display: none;
		color: $blue;
		font-size: 2.4rem;
		cursor: pointer;
	}
}

@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		header {
			nav {
				padding-right: 3rem;
				left: 0;
				.link-list {
					justify-content: space-between;
				}
			}
		}
	}
}

@media (max-width: 991px) {
	header {
		nav {
			position: absolute;
			width: 100%;
			background-color: rgba(0, 0, 0, 0.8);
			top: 7rem;

			.link-list {
				display: block;
				text-align: center;

				.main-li {
					background-color: $white;
					font-family: DINNextLTPro-normal;
					border-bottom: 0.1rem solid $border-color;
					height: 5rem;
					@include column-between-center;
					flex-flow: column-reverse;
					cursor: pointer;
					.main-li-a {
						width: 100%;
						font-size: 2.5rem;
						flex-flow: column-reverse;
					}

					.drop-down-menu {
						width: 100%;
						height: 100%;
						display: flex;
						flex-flow: column-reverse;
						justify-content: space-between;

						.selection-bar {
							height: 0.8rem;
						}
						.filler-bar {
							height: 0.8rem;
						}

						.container {
							display: flex;

							.services-drop-down {
								font-weight: 400;
								font-size: 2rem;
								margin: 0;
								@include flex-center-center;
								@include blue-font-normal;
							}
							div {
								flex: 1;
								display: flex;
								padding-right: 3rem;
								display: flex;
								justify-content: flex-end;
								align-items: center;

								i {
									flex: 1;
									text-align: right;
									font-size: 3rem;
								}
							}

							a {
								flex: 1;
								font-size: 2.2rem;
								color: $blue;
								@include flex-center-center;
							}
						}
					}
				}

				.main-li:first-of-type {
					border-top: 0.1rem solid $border-color;
				}

				.main-li:hover {
					h2 {
						color: $red;
					}
					.desktop-sub-menu {
						display: none;
					}
				}

				.main-li.active {
					h2 {
						color: $red;
					}
					.desktop-sub-menu {
						display: none;
					}
					.drop-down-menu {
						.container {
							.services-drop-down {
								color: $red;
							}
						}
					}
				}

				.li-languages {
					@include flex-center-center;
					flex-flow: row;
				}

				.sub-menu {
					li {
						background-color: $grey;
						border-bottom: 0.1rem solid $border-color;
						@include grey-font-normal;
						height: 5rem;
						@include column-between-center;
						flex-flow: column-reverse;
						cursor: pointer;

						.sub-link {
							@include column-between-center;
							flex-flow: column-reverse;
							height: 100%;
							width: 100%;
							text-decoration: none;
							div {
								height: 0.8rem;
								width: 100%;
							}

							h2 {
								margin: 0;
								font-size: 2rem;
								text-decoration: none;
								@include blue-font-light;
							}
						}
					}

					li.active {
						@include active-sub-nav-item;
					}

					li:hover {
						@include active-sub-nav-item;
					}
				}
			}
		}

		.nav-active {
			display: block;
			height: 100vh;
		}

		.nav-inactive {
			display: none;
		}

		.menu-toggle {
			display: block;
			padding-right: 3rem;
		}
	}
}

@media (max-width: 420px) {
	header {
		.logo {
			padding-left: 2rem;
			a {
				img {
					max-width: 10rem;
				}
			}
		}

		.menu-toggle {
			visibility: visible;
			padding-right: 2rem;
		}
	}
}

@media (max-width: 382px) {
	header {
		.logo {
			padding-left: 2rem;
			a {
				img {
					max-width: 10rem;
				}
			}
		}

		.menu-toggle {
			visibility: visible;
			padding-right: 2rem;
		}
	}
}
