@import '../Shared/Shared.scss';

.jumbotron {
	margin-top: $nav-height;
	height: 56rem;
	@include flex-center-center;
	overflow: hidden;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-image: url('../../images/home/Homepage-header.png');

	.jumbotron-text-background {
		height: 40.6rem;
		width: 60%;
		background-color: $blue;
		color: $white;
		@include flex-center-center;

		.jumbotron-text-container {
			height: 80%;
			width: 80%;
			@include flex-center-center;

			h1 {
				font-size: 3vw;
				@include white-font-bold;
				font-weight: bolder;
				margin: 0;
			}
		}
	}
}

@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		.jumbotron {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			background-image: url('../../images/home/Homepage-header.png');
		}
	}
}

@media (max-width: 1570px) {
	.jumbotron {
		height: 56rem;
		.jumbotron-text-background {
			.jumbotron-text-container {
				h1 {
					font-size: 4vw;
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	.jumbotron {
		width: auto;
		.jumbotron-text-background {
			.jumbotron-text-container {
				h1 {
					font-size: 5vw;
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.jumbotron {
		.jumbotron-text-background {
			height: 40vw;
		}
	}
}

@media (max-width: 850px) {
	.jumbotron {
		.jumbotron-text-background {
			.jumbotron-text-container {
				h1 {
					font-size: 6vw;
				}
			}
		}
	}
}

@media (max-width: 800px) {
	.jumbotron {
		height: 34rem;
		.jumbotron-text-background {
			height: auto;
			.jumbotron-text-container {
				margin: 3rem;
				h1 {
					font-size: 5vw;
				}
			}
		}
	}
}

@media (max-width: 570px) {
	.jumbotron {
		height: 22rem;
	}
}

@media (max-width: 500px) {
}

@media (max-width: 350px) {
}
