@font-face {
	font-family: 'DINNextLTPro-normal';
	src: url('webfonts/3A1D9F_0_0.eot');
	src: url('webfonts/3A1D9F_0_0.eot?#iefix') format('embedded-opentype'),
		url('webfonts/3A1D9F_0_0.woff2') format('woff2'),
		url('webfonts/3A1D9F_0_0.woff') format('woff'),
		url('webfonts/3A1D9F_0_0.ttf') format('truetype');
}
/*-Light*/
@font-face {
	font-family: 'DINNextLTPro-light';
	src: url('webfonts/3A1DF1_0_0.eot');
	src: url('webfonts/3A1DF1_0_0.eot?#iefix') format('embedded-opentype'),
		url('webfonts/3A1DF1_0_0.woff2') format('woff2'),
		url('webfonts/3A1DF1_0_0.woff') format('woff'),
		url('webfonts/3A1DF1_0_0.ttf') format('truetype');
	font-weight: lighter;
}
/*-Bold*/
@font-face {
	font-family: 'DINNextLTPro-bold';
	src: url('webfonts/3A1DF1_1_0.eot');
	src: url('webfonts/3A1DF1_1_0.eot?#iefix') format('embedded-opentype'),
		url('webfonts/3A1DF1_1_0.woff2') format('woff2'),
		url('webfonts/3A1DF1_1_0.woff') format('woff'),
		url('webfonts/3A1DF1_1_0.ttf') format('truetype');
	font-weight: bold;
}

@font-face {
	font-family: 'DINNextLTPro-heavy';
	src: url('webfonts/3A1DF1_2_0.eot');
	src: url('webfonts/3A1DF1_2_0.eot?#iefix') format('embedded-opentype'),
		url('webfonts/3A1DF1_2_0.woff2') format('woff2'),
		url('webfonts/3A1DF1_2_0.woff') format('woff'),
		url('webfonts/3A1DF1_2_0.ttf') format('truetype');
}
/*-Medium*/
@font-face {
	font-family: 'DINNextLTPro-medium';
	src: url('webfonts/3A1DF1_3_0.eot');
	src: url('webfonts/3A1DF1_3_0.eot?#iefix') format('embedded-opentype'),
		url('webfonts/3A1DF1_3_0.woff2') format('woff2'),
		url('webfonts/3A1DF1_3_0.woff') format('woff'),
		url('webfonts/3A1DF1_3_0.ttf') format('truetype');
}
