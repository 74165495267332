@import '../Shared/Shared.scss';

.projects {
	margin-top: $nav-height;
	@include column-center-center;

	p {
		@include blue-font-normal;
		font-size: 3rem;
		margin-bottom: 14rem;
	}

	.construction {
		color: $red;
		font-size: 6rem;
	}

	.projects-grid {
		display: grid;
		grid-template-columns: 33.3% 33.3% 33.3%;
		justify-items: center;
		grid-auto-rows: 1fr;
		width: 100%;
		max-width: 124.6rem;
		margin-bottom:2rem;

		.project-container {
			padding: 2rem;
			background-repeat: no-repeat;
			background-size: 90%;
			background-position-x: center;
			display: flex;
			flex-flow: column;
			align-items: center;
			max-width: 36rem;
			min-width: 30rem;
			text-decoration: none;

			.project-filler {
				min-height: 16rem;
				// max-height: 16rem;
			}

			.project-data {
				width: 80%;
				background-color: $white;
				display: flex;
				flex-flow: column;
				align-items: center;

				.project-type {
					text-align: center;
					width: 90%;
					@include red-font-bold;
					font-size: 1.6rem;
					margin-bottom: 0;
				}
				.project-title {
					text-align: center;
					width: 85%;
					@include blue-font-bold;
					font-size: 2rem;
				}
				.project-description {
					text-align: center;
					width: 90%;
					margin: 0;
					font-size: 1.6rem;
				}
			}
		}
	}
}

.projects-sub {
	margin-top: 3rem;
	margin-bottom: 5rem;
}

@media (max-width: 991px) {
	.projects {
		.projects-grid {
			grid-template-columns: 50% 50%;
		}
	}
	.projects-sub {
		.projects-grid {
			grid-template-columns: 100%;
		}
	}
}

@media (max-width: 705px) {
	.projects {
		.projects-grid {
			grid-template-columns: 100%;
		}
	}
}
