@import '../Shared/Shared.scss';

.info-button {
	width: 100%;
	display: flex;
	justify-content: center;
	height: auto;

	.info-button-container {
		width: 70%;
		@include flex-center-center;
		flex-flow: column;
		max-width: 103rem;

		.title {
			font-size: 4.3rem;
			margin-bottom: 1rem;
			@include red-font-bold;
		}

		.bar {
			width: 15rem;
			height: 0.5rem;
			background-color: $red;
		}

		.info {
			margin: 2rem 0;
			font-size: 2.2rem;
			@include blue-font-normal;
		}

		.see-more-btn {
			text-decoration: none;
			@include flex-center-center;
			height: 4rem;
			width: 23rem;
			background-color: $dark-grey;
			border: none;
			@include white-font-medium;
			cursor: pointer;
			font-size: 1.8rem;
			margin-bottom: 4.15rem;
		}

		.see-more-btn:hover {
			background-color: $red;
		}
	}
}

@media (max-width: 991px) {
	.info-button {
		.info-button-container {
			width: 80%;

			.title {
				font-size: 5vw;
			}
			.info {
				font-size: 4vw;
			}
		}
	}
}

@media (max-width: 500px) {
	.info-button {
		.info-button-container {
			width: 80%;
		}
	}
}

@media (max-width: 400px) {
	.info-button {
		.info-button-container {
			width: 80%;

			.see-more-btn {
				width: 18rem;
				font-size: 1.5rem;
			}
		}
	}
}
