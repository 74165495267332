@import '../../fonts/fonts.css';
$blue: #001e3c;
$red: #c0392b;
$dark-grey: #555555;
$light-grey: #f0f0f0;
$border-color: rgb(179, 179, 179);
$grey: rgb(214, 214, 214);
$white: #fff;
$nav-height: 7rem;
$max-width: 1440px;

@mixin icon($path) {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-image: url($path);
}

@mixin flex-center-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin flex-between-center {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin flex-end-end {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

@mixin flex-start-center {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

@mixin flex-evenly-center {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

@mixin column-between-center {
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	align-items: center;
}
@mixin column-center-center {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}

@mixin column-between-start {
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	align-items: flex-start;
}

@mixin zero-margin-padding {
	margin: 0;
	padding: 0;
}

@mixin blue-font-normal {
	color: $blue;
	font-family: DINNextLTPro-normal;
}

@mixin blue-font-medium {
	color: $blue;
	font-family: DINNextLTPro-medium;
}

@mixin blue-font-light {
	color: $blue;
	font-family: DINNextLTPro-light;
}

@mixin blue-font-bold {
	color: $blue;
	font-family: DINNextLTPro-bold;
}

@mixin grey-font-normal {
	color: $grey;
	font-family: DINNextLTPro-normal;
}

@mixin red-font-bold {
	color: $red;
	font-family: DINNextLTPro-bold;
}

@mixin red-font-normal {
	color: $red;
	font-family: DINNextLTPro-normal;
}

@mixin white-font-bold {
	color: $white;
	font-family: DINNextLTPro-bold;
}

@mixin white-font-medium {
	color: $white;
	font-family: DINNextLTPro-medium;
}

@mixin white-font-normal {
	color: $white;
	font-family: DINNextLTPro-normal;
}

@mixin grey-font-normal {
	color: $grey;
	font-family: DINNextLTPro-normal;
}

@mixin dark-grey-font-normal {
	color: $dark-grey;
	font-family: DINNextLTPro-normal;
}

@mixin dark-grey-font-heavy {
	color: $dark-grey;
	font-family: DINNextLTPro-heavy;
}

body {
	max-width:$max-width;
	margin:auto;
}

.quali-button {
	height: 4rem;
	width: 23rem;
	background-color: $dark-grey;
	border: none;
	color: #fff;
	cursor: pointer;
	font-size: 1.8rem;
}

.font-light {
	font-family: DINNextLTPro-light;
}

.font-normal {
	font-family: DINNextLTPro-normal;
}

.font-medium {
	font-family: DINNextLTPro-medium;
}

.font-bold {
	font-family: DINNextLTPro-bold;
}

.font-heavy {
	font-family: DINNextLTPro-heavy;
}
.youtube-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.youtube-video-container::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
