@import '../Shared/Shared.scss';

.info {
	margin-top: 7rem;
	@include flex-center-center;
	flex-flow: column;

	.info-img-container {
		width: 100%;
		max-width:$max-width;
		height: 50.4rem;
		overflow: hidden;
		position: absolute;
		top: 0;
		overflow: hidden;
		z-index: -1;
		margin-top: 6rem;

		img.info-bg-image {
			width: 100%;
			margin-top: -11rem;
			z-index: -1;
		}
	}

	.info-container {
		width: 80%;
		max-width: 107.1rem;
		margin-top: 29.3rem;
		background-color: $white;
		@include flex-center-center;
		flex-flow: column;
		padding: 0 6rem;

		img {
			color: $blue;
			height: 8rem;
			margin-top: 3rem;
		}

		.info-title {
			@include red-font-bold;
			font-size: 4.3rem;
		}

		.info-sub-title {
			width: 100%;
			text-align: left;
			font-size: 3rem;
			font-family: DINNextLTPro-bold;
			margin-bottom: 0rem;

			a {
				color: $dark-grey;
				text-decoration: none;
			}

			a:hover {
				color: $dark-grey;
				text-decoration: underline;
			}
		}

		.info-bar {
			margin-bottom: 3rem;
			background-color: $red;
			height: 0.6rem;
			width: 10rem;
		}

		.info-text {
			font-family: DINNextLTPro-normal;
			width: 100%;
			font-size: 2.2rem;
			text-align: left;
			span {
				display: block;
			}

			a {
				color: black;
				font-family: DINNextLTPro-normal;
			}
		}

		.info-list {
			width: 100%;
			text-align: left;
			font-size: 2.2rem;
		}

		.info-block {
			width: 100%;
			background-color: $blue;
			margin-top: 7rem;
			@include flex-center-center;

			p {
				@include flex-center-center;
				font-size: 2rem;
				font-family: DINNextLTPro-normal;
				text-align: left;
				color: $white;
				width: 90%;
				height: 80%;
			}
		}

		.project-type {
			margin-top: 5rem;
			@include dark-grey-font-heavy;
			font-size: 2.4rem;
		}

		.project-title {
			width: 70%;
			@include red-font-bold;
			font-size: 4.3rem;
		}

		.project-description {
			@include blue-font-normal;
			font-size: 2rem;
		}

		.project-explanation-grid {
			width: 100%;

			.project-explanation {
				padding: 2rem;
				.project-sub-title {
					@include blue-font-bold;
					text-align: left;
					font-size: 2.4rem;
					border-bottom:solid 2px $blue;
				}
				.project-sub-description {
					@include blue-font-normal;
					text-align: left;
					font-size: 2rem;
				}
			}
		}
	}
}

@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		.info {
			img.info-bg-image {
				left: 0;
			}
		}
	}
}

@media (max-width: 1500px) {
	.info {
		margin-top: 7rem;
		@include flex-center-center;

		img.info-bg-image {
			width: 100%;
			margin-top: 0;
		}
	}
}

@media (max-width: 1000px) {
	.info {
		margin-top: 7rem;
		@include flex-center-center;

		.info-container {
			margin-top: 25rem;
		}

		img.info-bg-image {
			// width: 100%;
			// margin-top: 0;
		}
	}
}

@media (max-width: 991px) {
	.info {
		.info-img-container {
			img.info-bg-image {
				width: 150%;
				margin-top: 0rem;
				transform: translate(-25%, -25%);
				z-index: -1;
			}
		}

		.info-container {
			.project-explanation-grid {
				grid-template-columns: 100%;
			}
		}
	}
}

@media (max-width: 900px) {
	.info {
		margin-top: 7rem;
		@include flex-center-center;

		img.info-bg-image {
			// width: 100%;
			// margin-top: 0;
		}
	}
}

@media (max-width: 800px) {
	.info {
		margin-top: 7rem;
		@include flex-center-center;

		img.info-bg-image {
			width: 100%;
		}

		.info-container {
			width: 60%;
			margin-top: 25rem;
			.info-title {
				font-size: 3rem;
			}
			.info-sub-title {
				font-size: 2.3rem;
			}
			.info-text {
				font-size: 2rem;
			}
			img {
				height: 4rem;
			}

			.info-list {
				width: 100%;
				text-align: left;
				font-size: 1.7rem;
			}
			.project-type {
				width: 100%;
				font-size: 2rem;
			}

			.project-title {
				width: 100%;
				font-size: 4rem;
			}
		}
	}
}

@media (max-width: 740px) {
	.info {
		.info-container {
			padding: 0 5rem;
			width: 70%;
			margin-top: 14rem;
		}
	}
}

@media (max-width: 570px) {
	.info {
		// margin-top: 7rem;
		@include flex-center-center;

		img.info-bg-image {
			// width: 100%;
		}

		.info-container {
			padding: 0 3rem;
			width: 80%;
			margin-top: 14rem;

			.info-title {
				font-size: 3rem;
			}
			.info-sub-title {
				font-size: 2.3rem;
			}
			.info-text {
				font-size: 2rem;
			}
		}
	}
}

@media (max-width: 500px) {
	.info {
		margin-top: 7rem;
		@include flex-center-center;

		img.info-bg-image {
			// width: 100%;
		}

		.info-container {
			padding: 0 3rem;
			width: 85%;
			margin-top: 14rem;

			.info-title {
				font-size: 2rem;
			}
			.info-sub-title {
				font-size: 1.7rem;
			}
			.info-text {
				font-size: 2rem;
			}

			.project-title {
				font-size: 3.2rem;
			}
		}
	}
}

@media (max-width: 420px) {
	.info {
		.info-container {
			margin-top: 10rem;
			.project-title {
				font-size: 3rem;
			}
		}
	}
}

@media (max-width: 350px) {
	.info {
		margin-top: 7rem;
		@include flex-center-center;

		img.info-bg-image {
			// width: 100%;
			margin-top: 3rem;
		}

		.info-container {
			padding: 0 2rem;
			width: 80%;
			margin-top: 14rem;

			.info-title {
				font-size: 2rem;
			}

			.project-title {
				font-size: 2.5rem;
			}
		}
	}
}
